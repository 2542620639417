import Endpoint from './Endpoint'


const Endpoints = ({ endpoints}) => {
  
    return (
      <div className='container'>
        {endpoints.map((endpoint) => (
          <Endpoint key={endpoint.key} endpoint={endpoint}/>
          
        ))}
      </div>
    )
  }
  
  export default Endpoints