import { useState } from 'react'
import React from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import Loading from './Loading';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
// import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';


const axios = require('axios');
const animatedComponents = makeAnimated();



const Endpoint = ({endpoint}) => {

    const [username, setUsername] = useState('')
    const [tag, setTag] = useState('')
    const [region, setRegion] = useState('')
    const [platform, setPlatform] = useState('')
    const [playlists, setPlaylists] = useState('')
    const [stats, setStats] = useState('')
    const [rURL, setRURL] = useState(' ')
    const [loadingPer, setLoadingPer] = useState('')
    const [resetLoading, setResetLoading] = useState(false)
    const [sData, setSData] = useState(' ')
    const [usernameError, setUsernameError] = useState(false)
    const [tagError, setTagError] = useState(false)
    const [regionError, setRegionError] = useState(false)
    const [platfromError, setPlatformError] = useState(false)

    
    



    const customStyles = {
        option: (provided, state) => ({
          ...provided,
            padding: "20px",
            backgroundColor: state.isFocused ? "grey" : provided.backgroundColor,
        }),
        control: (provided, state) => ({
            ...provided,
            width: "50%",
            minWidth:"300px",
            backgroundColor:"#495057",
            '&:hover': { borderColor: '#fff'}, // border style on hover
            
            border: '1px solid #fff', // default border color
            boxShadow: state.isFocused ? '0 0 0 1px #fff':'none',
            borderRadius: "10px",
            
            
            
            

        }),
        menu: (provided, state) => ({
            // none of react-select's styles are passed to <Control />
                ...provided,
                backgroundColor:"#495057",
                width: "50%",
                minWidth:"40px",
            //   borderRadius: "10px",
        }),

        singleValue: (provided, state) => ({
            // none of react-select's styles are passed to <Control />
              ...provided,
            //   backgroundColor:"#ffaa",
            color:"white",
        }),
        input: (provided, state) => ({
            // none of react-select's styles are passed to <Control />
              ...provided,
            //   backgroundColor:"#ffaa",
            color:"white",
        }),
        multiValue: (provided, state) => ({
            // none of react-select's styles are passed to <Control />
              ...provided,
            //   backgroundColor:"#ffaa",
            fontSize:15,
        }),
    }

    const customStyles_2 = {
        option: (provided, state) => ({
          ...provided,
            padding: "20px",
            backgroundColor: state.isFocused ? "grey" : provided.backgroundColor,
            // textAlign: "center"
        }),
        control: (provided, state) => ({
            ...provided,
            width: "200px",
            backgroundColor:"#495057",
            '&:hover': { borderColor: '#fff'}, // border style on hover
            
            border: '1px solid #fff', // default border color
            boxShadow: state.isFocused ? '0 0 0 1px #fff':'none',
            borderRadius: "10px",
            
            
            

        }),
        menu: (provided, state) => ({
            // none of react-select's styles are passed to <Control />
              ...provided,
              backgroundColor:"#495057",
              width: "200px",
            //   borderRadius: "10px",
        }),

        singleValue: (provided, state) => ({
            // none of react-select's styles are passed to <Control />
              ...provided,
            //   backgroundColor:"#ffaa",
            color:"white",
        }),
        input: (provided, state) => ({
            // none of react-select's styles are passed to <Control />
              ...provided,
            //   backgroundColor:"#ffaa",
            color:"white",
        }),
    }


    const onSubmit = (e) => {
        e.preventDefault()

        var plist = "";

        if (playlists.length) {

            plist = '?playlists='.concat(playlists);
            
        }

        var slist = "";

        if (stats.length) {

            slist = '?stats='.concat(stats)            
        }

        if (username === "") {
            setUsernameError(true)

    
        }else{
            setUsernameError(false)
        }
        if (platform === "") {
            setPlatformError(true)
    
        }else{
            setPlatformError(false)
        }
        if (tag === "") {
            setTagError(true)
    
        }else{
            setTagError(false)
        }
        if (region === "") {
            setRegionError(true)
    
        }else{
            setRegionError(false)
        }
        


        
        setLoadingPer('0%')
        let url = endpoint.route.replace("GET ","")
        .replace(":platform",platform)
        .replace(":username",username)
        .replace(":region",region)
        .replace(":tag",tag)
        .concat(plist)    
        .concat(slist) 
        
        
        url = url.replace("///","/").replace("//","/")

        url = encodeURI(url);
        

        url = "https://api.xnugget.com"+url;

        

        // return

        setRURL("Loading ...")
        setSData("Loading ...")
        try {
            axios.get(url,{
                headers: {
                  "Content-Type":"application/json"
                }
            }).then((result) => {
                setRURL(url)
                setLoadingPer("")
                // console.log(result)
                setSData(result.data)
                
            }).catch((err) => {
                setRURL(url)
                setLoadingPer("")
    
                setSData(err)
                
            });
            
        } catch (error) {
            setRURL(url)
            setLoadingPer("")
    
            setSData(error)
            
        }
        

    }
  


  return (
    <div
      className={`endpoint`}
    >
        <div className='endpoint-body'>

            <div className='endpoint-title'>
                <Tooltip 
                        title={<Typography fontSize={12} fontFamily={"din-next !important"}>Required request method.</Typography>}
                        disableInteractive
                        enterDelay={500} 
                        leaveDelay={200}
                        >
                    <span className='badge bg-gradient-info' style={{"marginRight": "10px"}}>GET</span>
                </Tooltip>
                <p className="card-title" style={{display:"inline"}}>{endpoint.title}</p>
            </div>
            <br></br>
            <a className="endpoint-description" style={{"marginBottom": "15px"}}>{endpoint.description}</a>

            <div className="codemirror" style={{"lineHeight": "3","marginBottom": "15px","overflow": "hidden"}}>
                    <pre>{endpoint.route}</pre>
            </div>

            <form id="accountform" onSubmit={onSubmit}>
                <div className="form-group2">

                <Collapse in={usernameError}>
                    <Alert
                    severity="error"
                    action={
                        <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setUsernameError(false);
                        }}
                        >
                        <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: 2 }}
                    >
                    Username not given!
                    </Alert>
                </Collapse>

                    <Tooltip 
                        title={<Typography fontSize={12} fontFamily={"din-next !important"}>{endpoint.key===1?"Platform Username (If Steam use ID or custom url name).":"Platform Username."}</Typography>}
                        disableInteractive
                        enterDelay={500} 
                        leaveDelay={200}
                        >
                        <label style={{paddingBottom:"10px"}} htmlFor="AccountName">Username</label>
                    </Tooltip>
                    <input 
                        type="text" 
                        className="form-control" 
                        id="AccountName"
                        value={username} 
                        placeholder="Nugget" 
                        onChange={(e) => setUsername(e.target.value)}
                        style={{"width":"10%",display:"inline",marginLeft:"10px",minWidth:"100px"}

                    }/>
                </div>

                {endpoint.title === "Valorant" ?

                <div className="form-group2">
                    <Collapse in={tagError}>
                        <Alert
                        severity="error"
                        action={
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setTagError(false);
                            }}
                            >
                            <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        Tag not given!
                        </Alert>
                    </Collapse>
                    <Tooltip 
                        title={<Typography fontSize={12} fontFamily={"din-next !important"}>Riot account tag (Letters or numbers after the #).</Typography>} 
                        disableInteractive 
                        enterDelay={500} 
                        leaveDelay={200}
                        >
                        <label style={{paddingBottom:"10px"}} htmlFor="AccountTag">Tagline</label>
                    </Tooltip>
                    
                    <input 
                        type="text" 
                        className="form-control" 
                        id="AccountTag"
                        value={tag}
                        placeholder="321" 
                        style={{"width":"10%",display:"inline",marginLeft:"10px",marginTop:"10px",minWidth:"100px"}}
                        onChange={(e) => setTag(e.target.value)}
                        
                    />
                </div>
                : <></>
                }

                {endpoint.platform === true ? <div className="form-group1">
                    <Collapse in={platfromError}>
                        <Alert
                        severity="error"
                        action={
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setPlatformError(false);
                            }}
                            >
                            <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        Platform not given!
                        </Alert>
                    </Collapse>
                    <Tooltip 
                        title={<Typography fontSize={12} fontFamily={"din-next !important"}>Platform user plays on.</Typography>}
                        disableInteractive
                        enterDelay={500} 
                        leaveDelay={200}
                        >
                        <label htmlFor="SelectPlatform">Platform</label>
                    </Tooltip>
                    
                    <Select 
                            // closeMenuOnSelect={false}
                            components={animatedComponents}
                            options={endpoint.platforms}
                            onChange={(e) => setPlatform(e.value)}
                            styles={customStyles}
                    />
                </div> : <></>
                }
                {endpoint.playlist === true ? <div className="form-group1">

                    <Tooltip 
                        title={<Typography fontSize={12} fontFamily={"din-next !important"}>Playlist you would like data on.</Typography>}
                        disableInteractive
                        enterDelay={500} 
                        leaveDelay={200}
                        >
                        <label style={{paddingTop:"10px"}} htmlFor="SelectPlaylist">Playlist</label>
                    </Tooltip>
                   
                    <Select 
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            options={endpoint.playlists}
                            isMulti
                            styles={customStyles}
                            onChange={(e) => {setPlaylists(e.map((element) => element.value).join(","))}}
                                

                    />
                </div> : <></>
                }
                {endpoint.region === true ? <div className="form-group1">
                    <Collapse in={regionError}>
                        <Alert
                        severity="error"
                        action={
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setRegionError(false);
                            }}
                            >
                            <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        Region not given!
                        </Alert>
                    </Collapse>
                    
                    <Tooltip 
                        title={<Typography fontSize={12} fontFamily={"din-next !important"}>If you want to select LATAM or BR as your region, use the NA the region.</Typography>}
                        disableInteractive
                        enterDelay={500} leaveDelay={200}
                        
                        >
                        <label htmlFor="SelectRegion">Region</label>
                    </Tooltip>
                   
                    <Select 
                            // closeMenuOnSelect={false}
                            components={animatedComponents}
                            options={endpoint.regions}
                            // isMulti
                            styles={customStyles_2}
                            onChange={(e) => setRegion(e.value)}
                    />
                </div> : <></>
                }

                {endpoint.stat === true ? <div className="form-group1">

                    <Tooltip 
                        title={<Typography fontSize={12} fontFamily={"din-next !important"}>Stats you would like to return.</Typography>}
                        disableInteractive
                        enterDelay={500} 
                        leaveDelay={200}
                        >
                        <label style={{paddingTop:"10px"}} htmlFor="SelectStats">Stats</label>
                    </Tooltip>
                   
                    <Select 
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            options={endpoint.stats}
                            isMulti
                            styles={customStyles}
                            onChange={(e) => {setStats(e.map((element) => element.value).join(","))}}
                                

                    />
                </div> : <></>
                }
                <Tooltip 
                        title={<Typography fontSize={12} fontFamily={"din-next !important"}>Send request.</Typography>}
                        disableInteractive
                        enterDelay={500} 
                        leaveDelay={200}
                        >
                
                    <button type="submit" className="btn bg-gradient-secondary" id="buttonaccount" style={{marginTop:"10px"}}><span>Execute</span></button>
                </Tooltip>

            </form>

            {loadingPer === "" ? <></>:
            <Loading
                loadingPer={loadingPer}
                setLoadingPer={setLoadingPer}
                resetLoading={resetLoading}
                setResetLoading={setResetLoading}
            />


            }

            
            
            <a className="endpoint-description" style={{"marginBottom": "15px"}}>Request URL</a>

            <div className="codemirror" style={{"lineHeight": "3","marginBottom": "15px"}}>
                <pre>{rURL}</pre>
            </div>

            <a className="endpoint-description" style={{"marginBottom": "15px"}}>Response</a>

            <div className="codemirror" style={{"lineHeight": "3","marginBottom": "15px"}}>
                <pre  style={{overflowWrap:"break-word"}}>{sData}</pre>
            </div>
            
            
            


        </div>
          
        

      
        


        {/* <form className='endpoint' onSubmit={console.log("sub")}>
            <div className='form-control'>
                <label><h3>Command</h3></label>
                <input
                type='text'
                placeholder='Add command'
                value="{name}"
                onChange={(e) => {console.log("com")}}
                />
            </div>
            <div className='form-control'>
                <label>Day & Time</label>
                <input
                type='text'
                placeholder='Add Day & Time'
                value={day}
                onChange={(e) => setDay(e.target.value)}
                />
            </div>

            <div className='form-control'>
                <label><h3>Command Response</h3></label>
                <textarea className="textarea" 
                value=""
                onChange={(e) => {console.log("res")}}
                placeholder="Nugget">

                </textarea>
                
            </div>
            
            
            <div className='form-control form-control-check'>
                <label>Set Active</label>
                <input
                type='checkbox'
                checked={true}
                value={true}
                onChange={(e) => {console.log("act")}}
                />
            </div>
            

            <input type='submit' value='Save Task' className='btn btn-block' />
        </form> */}
        
      
    </div>
    
  )
}

export default Endpoint
