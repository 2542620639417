import './App.css';
import Endpoints from './components/Endpoints'



const endpoints = [
  {
    key:1,
    title:"Rocket League",
    route:"GET /rl/:platform/:username",
    description:"Get rank stats for each gamemode.",
    platform:true,
    platforms:[
      { value: 'steam', label: 'Steam'},
      { value: 'epic', label: 'Epic'},
      { value: 'psn', label: 'Playstation'},
      { value: 'xbl', label: 'Xbox'},
      { value: 'switch', label: 'Switch'},

    
    ],
    playlist:true,
    playlists:[
      { value: 'UNRANKED', label: 'Casual'},
      { value: '1V1', label: '1v1'},
      { value: '2V2', label: '2v2'},
      { value: '3V3', label: '3v3'},
      { value: 'HOOPS', label: 'Hoops'},
      { value: 'RUMBLE', label: 'Rumble'},
      { value: 'DROPSHOT', label: 'Dropshot'},
      { value: 'SNOWDAY', label: 'Snowday'},
      { value: 'TOURNAMENT', label: 'Tournaments'},
    
    ]
  },
  {
    key:2,
    title:"Apex Legends",
    route:"GET /apex/:platform/:username",
    description:"Get rank for each gamemode.",
    platform:true,
    platforms:[
      { value: 'PC', label: 'Pc'},
      { value: 'PS4', label: 'Playstation'},
      { value: 'X1', label: 'Xbox'},
    
    ],
    playlist:true,
    playlists:[
      { value: 'RANK', label: 'Ranked'},
      { value: 'ARENA', label: 'Areans'},
    
    ],
  },
  { 
    key:3,
    title:"Valorant",
    route:"GET /val/:username/:tag/:region",
    description:"Get rank & elo information.",
    region:true,
    regions:[
      { value: 'ap', label: 'Asia-Pacific'},
      { value: 'eu', label: 'Europe'},
      { value: 'kr', label: 'Korea'},
      { value: 'na', label: 'North America'},
      
      
    
    ],
    stat:true,
    stats:[
      { value: 'currenttierpatched', label: 'Rank'},
      { value: 'mmr_change_to_last_game', label: 'Last Game'},
      { value: 'elo', label: 'Elo'},
      { value: 'ranking_in_tier', label: 'Tier'},
    
    ],
  },
]

function App() {


  return (
    
    <div className="App">
      <h1 className='title'>Nugget API</h1>


      <Endpoints 
        endpoints={endpoints}
      />

      
    </div>
  );
}

export default App;
