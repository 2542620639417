import { useEffect } from 'react'
import React from 'react';

const Loading = ({ loadingPer, setLoadingPer}) => {

    // function getRandomInt(min, max) {
    //     return Math.floor(Math.random() * (max - min + 1) + min);
    //   }

    const getTimeout = ()=>{

        if (parseInt(loadingPer) > 90) {
            return 500
            
        }else if (parseInt(loadingPer) > 50 ) {
            return 100
            
        }else if (parseInt(loadingPer) > 20 ) {
            return 10
            
        }else {
            return 1
            
        }

    }
    useEffect(()=>{

        const interval = setInterval(() => {
            if (parseInt(loadingPer) !== 99) {
                let temp = parseFloat(loadingPer.replace('%',""))+0.2

                if (temp===100) {
                    return
                    
                }

                setLoadingPer(`${temp.toString()}%`)
            }
            
        }, getTimeout());
        
        return () => {
            clearInterval(interval);
        }
        
    })
  
    return (
      <div className='loading-bar'>
            <a className="endpoint-description" style={{"marginBottom": "15px"}}>{`${parseInt(loadingPer)}%`}</a>
            <div style={{width:"100%",backgroundColor:"#495057",height:"20px",borderRadius:10}}>
                <div style={{width:loadingPer,backgroundImage: "linear-gradient(310deg, #2152ff 0%, #21d4fd 100%)",height:"20px",borderRadius:10}}>
                </div>
            </div>
      </div>
    )
  }
  
  export default Loading